@import '_var';
@import '_mixins';



@media(min-width: 992px) {
    .seller-panel-sidebar {
        width: 100%;
        display: grid;
        height: 100vh;

        section {
            overflow-y: visible;
            overflow-x: auto;


            @include custom-scrollbar;

            ::-webkit-scrollbar-thumb {
                background: $intensDarkColor;
                border-radius: 0px;
            }

            ::-webkit-scrollbar {
                width: 0px;
            }

            .custom-scroll-bar {
                overflow-y: scroll;
                border-top: 1px solid $tempColor;
            }


            .sidebar-img-container {
                width: 100%;
                height: 60px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0rem 20%;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }

            .sidebar-toggle-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $intensDarkColor;
                border-radius: 0.5rem;
                cursor: pointer;
                padding: 0.25rem;
                transition: .3s;
                height: 40px;
                background-color: $secondaryColor;
                color: $tempColor;


                &:hover {
                    background-color: $tempColor;
                    color: $secondaryColor;
                }

            }

            .sidebar-store-tab {
                border-bottom: 1px solid $tempColor;

                .sidebar-store-tab-in {
                    color: $intensDarkColor;
                    border-radius: 0.25rem;
                    transition: .3s;
                    text-decoration: none;

                    &:hover {
                        background-color: #F8F8F8;
                    }

                    .custom-store-menu {
                        position: fixed;
                        min-width: 250px;
                        left: 20%;
                        @include shadowLightBtn;
                        transform: translateY(32%);
                        padding: 1rem;
                        background-color: $secondaryColor;
                        border-radius: .25rem;

                        a {
                            padding: .25rem;
                            color: $themeColor;
                            border-radius: 0.25rem;

                            &:hover {
                                background-color: $subThemeColor;
                            }
                        }

                        .store-display-part {
                            text-decoration: none;
                            @include themeBtn;
                            background-color: $intensDarkColor;
                            transition: .3s;

                            &:hover {
                                background-color: $subThemeColor;
                                color: $themeColor;
                            }
                        }
                    }
                }
            }

            .final-outside-dropdown {
                position: fixed;
                width: 250px;
                height: 200px;
                background-color: red;
                transform: translate(100%, 0%);
            }

            .sidebar-user-tab {
                border-top: 1px solid $tempColor;
                border-bottom: 1px solid $tempColor;

                .sidebar-user-tab-in {
                    color: $themeColor;
                    border-radius: 0.5rem;
                    transition: .3s;
                    text-decoration: none;

                    &:hover {
                        background-color: #F8F8F8;
                    }

                    .custom-user-menu {
                        position: fixed;
                        min-width: 250px;
                        left: 20%;
                        bottom: 0%;
                        @include shadowLightBtn;
                        padding: 1rem;
                        background-color: $secondaryColor;
                        border-radius: .25rem;
                        z-index: 9999;

                        a {
                            padding: .25rem;
                            color: $themeColor;
                            border-radius: 0.25rem;

                            &:hover {
                                background-color: $subThemeColor;
                            }
                        }

                        .user-display-part {
                            text-decoration: none;
                            @include themeBtn;
                            transition: .3s;

                            &:hover {
                                background-color: $subThemeColor;
                                color: $themeColor;
                            }
                        }
                    }
                }
            }

            .sidebar-menu-list {
                .sidebar-menu-item {
                    border-radius: 0.25rem;
                    transition: .3s;
                    color: $themeColor;
                    text-decoration: none;
                    padding: .5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.5rem;
                    border-left: 3px solid transparent;

                    .is-counting-box {
                        position: absolute;
                        right: 7px;
                        width: 25px;
                        height: 25px;
                        background-color: $intensDarkColor;
                        color: $secondaryColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50px;
                        border: 1px solid $secondaryColor;
                    }

                    .is-label-box {
                        position: absolute;
                        right: 0px;

                        width: 22%;
                        aspect-ratio: 10/7.5;
                        // background-color: $intensDarkColor;
                        color: $secondaryColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transform: translate(10%, -45%) rotate(20deg);

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                        }
                    }

                    &:hover {
                        background-color: $intensDarkColor;
                        color: $secondaryColor;
                    }

                    .custom-menu-peta-item {
                        min-height: 80% !important;
                        display: grid;
                    }

                    ::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 3px gray;
                    }

                    ::-webkit-scrollbar {
                        width: 0px;
                    }

                    .sidebar-menu-sub-item {
                        position: fixed;
                        display: flex;
                        top: 50%;
                        bottom: 10px;
                        transform: translateY(-50%);
                        min-width: auto;
                        max-width: 80%;
                        min-height: auto;
                        max-height: 80%;
                        @include shadowLightBtn;
                        padding: 1rem;
                        background-color: $secondaryColor;
                        border-radius: .25rem;
                        overflow-y: scroll;


                        .custom-close {
                            position: absolute;
                            top: 0;
                            right: 0;
                            padding: .5rem .7rem;
                            background-color: lightgray;
                            opacity: .5;
                            transition: .3s;
                            border-radius: 0 0 0 50%;

                            &:hover {
                                opacity: 1;
                                background-color: $themeColor;
                                color: $secondaryColor;
                            }
                        }

                        a {
                            padding: .25rem;
                            color: $themeColor;
                            border-radius: 0.25rem;
                            text-decoration: none;

                            &:hover {
                                background-color: $subThemeColor;
                                color: $darkSubColor;
                            }
                        }
                    }
                }

                .custom-active-item {
                    color: $secondaryColor;
                    background-color: $intensDarkColor;


                    span {
                        svg {
                            transition: .3s;
                        }
                    }
                }
            }
        }
    }


    .custom-store-model {
        height: 0;
        width: 0;

        .modal-body {
            padding: 0 !important;
        }

        .modal-content {
            border: 0;
        }

        .modal-dialog {
            width: 0;
            margin: 0;
        }

        .custom-store-menu {
            position: fixed;
            min-width: 250px;
            left: 20%;
            @include shadowLightBtn;
            transform: translateY(85%);
            padding: 1rem;
            background-color: $secondaryColor;
            border-radius: .25rem;
            z-index: 9999;

            a {
                padding: .25rem;
                color: $themeColor;
                border-radius: 0.25rem;

                &:hover {
                    background-color: $subThemeColor;
                }
            }

            .store-display-part {
                text-decoration: none;
                @include themeBtn;
                transition: .3s;

                &:hover {
                    background-color: $subThemeColor;
                    color: $themeColor;
                }
            }
        }
    }
}

.custom-dropdown-menu {
    top: 0 !important;
    left: 100% !important;
    transform: translateX(0);
}

@media(max-width: 992px) {
    .seller-panel-sidebar {
        .custom-top-bar {
            height: 75px;
            padding: 0rem 0.5rem;
            @include flex-between-align;
            box-shadow: 0 0 10px $lightColor;
            background-color: $secondaryColor;

            .custom-top-bar-img-container {
                height: 100%;
                max-width: 125px;
                display: flex;
                align-items: center;

                img {
                    height: auto;
                    max-width: 100%;
                    object-fit: cover;
                }
            }

            .top-bar-end-part {
                .custom-top-bar-lightning {
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    border: 1px solid $intensDarkColor;
                    position: relative;
                    transition: .3s;
                    cursor: pointer;

                    .cutom-thunder {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &:hover {
                        color: $secondaryColor;
                        background-color: $darkSubColor;
                    }

                    .custom-store-menu {
                        position: absolute;
                        min-width: 250px;
                        right: 0%;
                        top: 185%;
                        @include shadowLightBtn;
                        padding: 1rem;
                        background-color: $secondaryColor;
                        border-radius: .25rem;
                        z-index: 9999;

                        a {
                            padding: .25rem;
                            color: $themeColor;
                            border-radius: 0.25rem;

                            &:hover {
                                background-color: $subThemeColor;
                            }
                        }

                        .store-display-part {
                            text-decoration: none;
                            @include themeBtn;
                            transition: .3s;

                            &:hover {
                                background-color: $subThemeColor;
                                color: $themeColor;
                            }
                        }
                    }
                }

                a {
                    color: $themeColor;
                    text-decoration: none;
                }

                .custom-top-bar-list-container {
                    cursor: pointer;
                    position: relative;

                    .custom-top-bar-list {
                        min-width: 300px;
                        position: absolute;
                        top: 150%;
                        right: 0;
                        @include custom-scrollbar;
                        @include shadowLightBtn;
                        padding: 0;
                        background-color: $secondaryColor;

                        .accordion {
                            height: 50vh;
                            overflow-y: scroll;
                            --bs-accordion-border-radius: 0px;
                        }
                    }

                    .custom-remove-icon {
                        .accordion-button::after {
                            display: none;
                        }
                    }
                }


            }
        }

        .custom-header-for-padding {
            button {
                padding: 0.25rem 1rem;
            }
        }
    }
}