@import 'var';
@import 'mixins';

.seller-account {
    min-height: 100vh;
    background-color: $sellerThemeColor;

    .seller-account-img-holder {
        div {
            width: 80%;
            aspect-ratio: 1/1;
            border-radius: 50%;
            border: 4px solid $borderColor;
            box-shadow: inset 0 0 10px $borderColor;

            svg {
                color: $darkSubColor;
                font-size: 60px;
            }
        }
    }
}

.seller-box {
    .image-container {
        border-radius: .5rem;
        position: relative;
        overflow: hidden;
        border: 2px solid $secondaryColor;
        width: 100%;
        aspect-ratio: 1/1;
        overflow: hidden;

        .gallery-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background-color: rgba(128, 128, 128, 0.1);
            border: 1px solid $borderColor;
        }

        .custom-bg-black {
            position: absolute;
            top: -100%;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.65), transparent);
            transition: .3s;
        }

        .icon-holder {
            position: absolute;
            top: -50px;
            right: 20px;
            // height: 25px;
            // width: 25px;
            // @include flex-center-align;
            // background-color: $secondaryColor;
            // color: red;
            // border-radius: .33rem;
            // transition: .3s;
            // cursor: pointer;
            display: flex;
            gap: 10px;

            .icon-btn {
                background-color: #ffffff;
                height: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
            }

        }

        &:hover .icon-holder {
            top: 20px;
        }

        &:hover .custom-bg-black {
            top: 0%;
        }
    }
}
@media(max-width: 768px) {
    .seller-box {
        .image-container {
            .icon-holder {
                top: 10px;
                right: 10px;
            }
            .custom-bg-black {
                top: 0%;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), transparent);
            }    
        }
    }
}