@import 'var';
@import 'mixins';

.custom-canvas {
    height: 100vh;

    .custom-canvas-row {
        height: 100%;
        align-content: space-between;

        .custom-canvas-header {
            height: 70px;
            background-color: $secondaryColor;
            border-bottom: 1px solid $borderColor;
            @include flex-between-align;

            .custom-undo-redo {
                cursor: pointer;

                i {
                    @include flex-center-align;
                    border-radius: .25rem;
                    transition: .3s;


                    &:hover:nth-child(1) {
                        transform: rotate(-45deg);
                    }

                    &:hover:nth-child(2) {
                        transform: rotate(45deg);
                    }
                }


                .custom-line {
                    height: 100%;
                    width: 2px;
                    background-color: $borderColor;
                }
            }
        }

        .custom-canvas-footer {
            height: 90px;
            @include flex-between-align;
            background-color: $secondaryColor;
            border-top: 1px solid $borderColor;

            .custom-canvas-footer-product {
                .custom-line {
                    height: 100%;
                    width: 2px;
                    background-color: $borderColor;
                }

                .img-holder {
                    width: 56px;
                    aspect-ratio: 1/1;
                    overflow: hidden;
                    border-bottom: 1px solid $darkColor;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .custom-price-show {
                    position: relative;
                    cursor: pointer;

                    &:hover .custom-price-box {
                        display: grid;
                    }

                    .custom-price-box {
                        position: absolute;
                        min-height: 50px;
                        min-width: 150px;
                        background-color: $secondaryColor;
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -100%);
                        cursor: default;
                        display: none;
                        border-radius: .5rem;
                        box-shadow: 0 0 10px $intensDarkColor;
                    }
                }
            }

            .custom-submit {
                @include darkBtn;
                background-color: $tempColor;
                border-radius: .25rem;
                transition: .3s;

                // &:hover {
                //     background-color: rgb(0, 32, 0);
                // }
            }
        }

        .custom-canvas-body {
            flex-grow: 1;
            height: calc(100% - 160px);
            @include flex-between-align;
            background-color: $sellerThemeColor;

            .custom-canvas-body-sidebar {
                height: 100%;
                width: 40%;
                transition: .5s;
                @include flex-between-align;
                justify-content: start;

                ::-webkit-scrollbar {
                    width: 0px;
                }

                .custom-canvas-body-sidebar-header {
                    transition: .5s;
                    height: 100%;
                    width: 75px;
                    border-right: 1px solid $borderColor;
                    background-color: $intensDarkColor;
                    color: $secondaryColor;
                    gap: 5px;
                    align-content: start;
                    overflow-y: scroll;

                    .custom-tab-list {
                        background-color: transparent;
                        aspect-ratio: 1/1;
                        @include grid-center-align;
                        text-align: center;
                        border-radius: .5rem;
                        cursor: pointer;
                        transition: .3s;
                        gap: .3rem;
                        line-height: 12px;

                        &:hover {
                            background-color: $secondaryColor;
                            color: $intensDarkColor;
                        }
                    }

                    .custom-tab-list.active-tab {
                        background-color: $secondaryColor;
                        color: $intensDarkColor;
                    }
                }

                .custom-canvas-body-sidebar-content {
                    height: 100%;
                    width: calc(100% - 75px);
                    position: relative;
                    transition: .5s;
                    background-color: $sellerThemeColor;

                    .custom-toggle-btn {
                        width: 25px;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        padding: .75rem 0rem;
                        background-color: $secondaryColor;
                        transform: translate(calc(-1px), -50%);
                        cursor: pointer;
                        border-radius: 0 .35rem .35rem 0;
                        transition: .3s;
                        border: 1px solid $borderColor;
                        border-left: 0;

                        &:hover {
                            box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5);
                        }

                        i {
                            @include flex-center-align;

                            &::before {
                                transform: rotate(180deg);
                                transition: .3s;
                            }
                        }
                    }

                    @include custom-scrollbar;

                    ::-webkit-scrollbar {
                        width: 8px;
                    }

                    .custom-sidebar-all-content {
                        width: calc(100% - 25px);
                        border-right: 1px solid $borderColor;
                        overflow-y: scroll;
                        transition: .5s;
                        background-color: #c3ebe1;

                        .list-of-colors {
                            flex-wrap: wrap;
                            border-radius: .5rem;
                            background-color: $secondaryColor;

                            div {
                                span {
                                    height: 25px;
                                    width: 25px;
                                    background-color: red;
                                    border-radius: .25rem;
                                    border: 1px solid $borderColor;
                                    cursor: pointer;
                                    position: relative;

                                    i {
                                        position: absolute;
                                        height: 100%;
                                        width: 100%;
                                        @include flex-center-align;
                                        align-items: center;
                                        color: black;
                                        border: 2px solid black;
                                        border-radius: .25rem;

                                        &::before {
                                            text-shadow: 0 0 5px white;
                                        }
                                    }
                                }
                            }
                        }

                        .list-of-sizes {
                            border-radius: .5rem;
                            background-color: $secondaryColor;

                            label {
                                @include flex-center-align;
                                cursor: pointer;
                                flex-wrap: wrap;

                                input {
                                    height: 15px;
                                    width: 15px;
                                    cursor: pointer;
                                }
                            }
                        }

                        .custom-text-edit-content {
                            display: grid;
                            height: 100%;
                            align-content: space-between;

                            .accordion-item {
                                border-radius: .5rem !important;
                                overflow: hidden;
                            }

                            // .accordion-item:last-of-type>.accordion-collapse{
                            //     border-radius: .5rem !important;
                            // }

                            textarea {
                                border-radius: .33rem;

                                &:focus,
                                &:active {
                                    outline: 0px solid $darkColor;
                                }
                            }

                            .custom-clear-btn {
                                @include lightBtn;
                                border-radius: .33rem;
                                border: 1px solid $themeColor;
                            }

                            .custom-apply-btn {
                                @include dark-sub-btn;
                                border-radius: .33rem;
                                background-color: $intensDarkColor;
                                border: 1px solid $intensDarkColor;
                            }

                            .accordion-button {
                                padding: .75rem 1rem;
                                @include flex-between-align;

                                &::after {
                                    display: none;
                                }

                                &:focus {
                                    outline: 0px;
                                    box-shadow: 0 0 0 transparent;
                                }

                                i {
                                    transition: .3s;
                                }
                            }

                            .accordion-button:not(.collapsed) {
                                background-color: transparent;
                                box-shadow: 0 0 0 transparent;
                                color: $darkColor;

                                i {
                                    transform: rotate(45deg);
                                }
                            }

                            .accordion-body {
                                padding: .5rem 1rem;
                            }

                            .custom-font-size-tab {

                                input[type=number]::-webkit-inner-spin-button,
                                input[type=number]::-webkit-outer-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }

                                .custom-font-value {
                                    border: 1px solid $borderColor;
                                    border-radius: .33rem;
                                    overflow: hidden;
                                    text-align: center;

                                    input {
                                        border: 0px;
                                        width: 100%;
                                        text-align: center;

                                        &:focus,
                                        &:active {
                                            outline: 0px;
                                        }
                                    }

                                    i {
                                        width: 100%;
                                        padding: .25rem;
                                        cursor: pointer;
                                        background-color: $borderColor;
                                        transition: .3s;

                                        &:active {
                                            background-color: $darkColor;
                                            color: $secondaryColor;
                                        }
                                    }
                                }

                                .custom-style-btn {
                                    @include flex-between-align;

                                    .custom-btns {
                                        cursor: pointer;
                                        padding: .25rem .5rem;
                                        border-radius: .33rem;
                                        background-color: $borderColor;
                                        transition: .3s;
                                        color: $intensDarkColor;

                                        &:hover {
                                            background-color: $intensDarkColor;
                                            color: $secondaryColor;
                                        }
                                    }

                                    .custom-btns.active {
                                        background-color: $intensDarkColor;
                                        color: $secondaryColor;
                                    }
                                }
                            }

                            .custom-font-color-tab {
                                .custom-font-color-tab-list {
                                    .colorpick-eyedropper-input-trigger {
                                        display: none;
                                    }

                                    input[type="color"] {
                                        appearance: none;
                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        border: none;
                                        width: 35px;
                                        /* Adjust as needed */
                                        height: 35px;
                                        border-radius: 50%;
                                        /* Adjust as needed */
                                        background-color: transparent;
                                        cursor: pointer;
                                        position: relative;
                                    }

                                    input[type="color"]::-webkit-color-swatch-wrapper {
                                        padding: 0;
                                    }

                                    input[type="color"]::-webkit-color-swatch {
                                        border: none;
                                        background: none;
                                    }

                                    input[type="color"]::before {
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        background: url('../Images/multi-color-1.png') no-repeat center center;
                                        background-size: contain;
                                        pointer-events: none;
                                        border-radius: 50%;
                                    }

                                    @include flex-between-align;
                                    justify-content: start;
                                    flex-wrap: wrap;
                                    gap: .5rem;

                                    span {
                                        height: 35px;
                                        width: 35px;
                                        background-color: $secondaryColor;
                                        border: 2px solid $borderColor;
                                        border-radius: 50%;
                                        cursor: pointer;
                                        overflow: hidden;

                                        i {
                                            @include flex-center-align;
                                            height: 100%;
                                            width: 100%;
                                            border: 3px solid $intensDarkColor;
                                            color: $intensDarkColor;
                                            border-radius: 50%;
                                            text-shadow: 0 0 5px $secondaryColor;
                                        }
                                    }
                                }

                                .color-code-show {
                                    input {
                                        width: 110px;
                                        text-align: center;
                                        border: 2px solid $borderColor;
                                        border-radius: .33rem;

                                        &:focus,
                                        &:active {
                                            outline: 0px;
                                        }
                                    }
                                }

                                .custom-text-shadow-list {

                                    .custom-range {
                                        height: 8px;
                                        background-color: #ddd;
                                        border-radius: 5px;
                                        margin: 0;
                                    }

                                    .custom-range::-webkit-slider-thumb {
                                        background-color: $intensDarkColor;
                                        border: 2px solid #FFFFFF;
                                        height: 22px;
                                        width: 22px;
                                        border-radius: 50%;
                                        margin-top: -7px;
                                        cursor: pointer;
                                    }

                                    .custom-range::-moz-range-thumb {
                                        background-color: $intensDarkColor;
                                        border: 2px solid #FFFFFF;
                                        height: 22px;
                                        width: 22px;
                                        border-radius: 50%;
                                        margin-top: -7px;
                                        cursor: pointer;
                                    }

                                    .custom-range::-ms-thumb {
                                        background-color: $intensDarkColor;
                                        border: 2px solid #FFFFFF;
                                        height: 22px;
                                        width: 22px;
                                        border-radius: 50%;
                                        margin-top: 0px;
                                        transform: translateY(-50%);
                                        cursor: pointer;
                                    }

                                    .type-div {
                                        width: 20%;

                                        input[type=number]::-webkit-inner-spin-button,
                                        input[type=number]::-webkit-outer-spin-button {
                                            -webkit-appearance: none;
                                            margin: 0;
                                        }

                                        input {
                                            width: 100%;
                                            padding: .25rem;
                                            border: 1px solid $borderColor;
                                            border-radius: .33rem;
                                            text-align: center;

                                            &:focus,
                                            &:active {
                                                outline: 0px;
                                            }
                                        }
                                    }
                                }
                            }

                            .custom-preview-box {
                                textarea {
                                    border: 1px dashed $intensDarkColor;
                                    background-color: transparent;
                                    height: 100px;
                                }
                            }
                        }

                        .custom-clipart-container {
                            .custom-clipart {

                                .custom-img-holder-clipart {
                                    width: 50px;
                                    aspect-ratio: 1/1;
                                    overflow: hidden;

                                    span {
                                        cursor: pointer;
                                        transition: .3s;
                                    }
                                }
                            }
                        }

                        .custom-upload-tab {
                            grid-template-rows: 180px 1fr;

                            .file-upload-form {
                                width: 100%;
                                height: fit-content;
                                display: flex;
                                align-items: center;
                                justify-content: center;


                                &:hover .file-upload-label svg {
                                    height: 40px;
                                }

                                .file-upload-label input {
                                    display: none;
                                }

                                .file-upload-label svg {
                                    height: 30px;
                                    fill: rgb(82, 82, 82);
                                    transition: .3s;
                                }

                                .file-upload-label svg.active {
                                    height: 40px;
                                }

                                .file-upload-label {
                                    cursor: pointer;
                                    background-color: #ffffff;
                                    padding: 1rem;
                                    width: 100%;
                                    border-radius: 1rem;
                                    border: 2px dashed rgb(82, 82, 82);
                                    box-shadow: 0px 0px 200px -50px rgba(0, 0, 0, 0.719);
                                    transition: .3s;

                                    &:hover {
                                        background-color: #eaeaea;
                                        border: 2px solid $intensDarkColor;
                                        color: $intensDarkColor;

                                        svg {
                                            fill: $intensDarkColor;
                                        }

                                        .browse-button {
                                            background-color: $intensDarkColor;
                                        }
                                    }
                                }

                                .file-upload-design {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 2px;
                                }

                                .browse-button {
                                    background-color: rgb(82, 82, 82);
                                    padding: .25rem 1rem;
                                    border-radius: 10px;
                                    color: white;
                                    transition: all 0.3s;
                                }

                                .browse-button:hover {
                                    background-color: rgb(14, 14, 14);
                                }


                            }

                            .file-upload-form.dragover .file-upload-label {
                                background-color: #eaeaea;
                                border: 2px solid $intensDarkColor;
                                color: $intensDarkColor;

                                svg {
                                    fill: $intensDarkColor;
                                }

                                .browse-button {
                                    background-color: $intensDarkColor;
                                }
                            }

                            .img-list-data {
                                height: 100%;
                                overflow-y: scroll;
                                @include custom-scrollbar;
                                overflow-x: hidden;
                                width: 100%;

                                .custom-img-list {

                                    .img-holder {
                                        width: 100%;
                                        aspect-ratio: 1/1;
                                        border-radius: .5rem;
                                        overflow: hidden;
                                        cursor: pointer;
                                        position: relative;
                                        border: 1px solid $borderColor;
                                        background-color: $secondaryColor;

                                        img {
                                            height: 100%;
                                            width: 100%;
                                            object-fit: cover;
                                            transition: .3s;
                                        }

                                        &:hover img {
                                            transform: scale(1.1);
                                        }

                                        &:hover span {
                                            opacity: 1;
                                            transform: translateY(0);
                                        }

                                        span {
                                            position: absolute;
                                            top: 10px;
                                            right: 10px;
                                            background-color: red;
                                            border-radius: .25rem;
                                            height: 25px;
                                            width: 25px;
                                            box-shadow: 0 0 10px black;
                                            @include flex-center-align;
                                            cursor: pointer;
                                            opacity: 0;
                                            transform: translateY(-120%);
                                            transition: .5s;

                                            i {
                                                color: $secondaryColor;
                                            }
                                        }

                                    }
                                }
                            }

                        }
                    }
                }

            }

            .custom-canvas-body-content {
                height: 100%;
                width: 100%;
                transition: .3s;
                background-color: $sellerThemeColor;
                display: grid;
                justify-content: center;
                @include custom-scrollbar;

                ::-webkit-scrollbar {
                    width: 8px;
                }

                /* Track */
                ::-webkit-scrollbar-track {
                    background-color: #c3ebe1;
                    border-radius: 5px;
                }

                ::-webkit-scrollbar-thumb {
                    background: $tempColor;
                    border-radius: 5px;
                }

                ::-webkit-scrollbar-thumb:hover {
                    background: $tempColor;
                }

                .final-display-body-content {
                    overflow-y: scroll;
                    min-height: 100%;
                    position: relative;

                    .canvas-body-sides-label {
                        background-color: $intensDarkColor;
                        color: $secondaryColor;
                        border-radius: .5rem;
                        @include flex-between-align;
                        // justify-content: start;
                        flex-wrap: wrap;

                        .canvas-body-sides-label-list {
                            border-radius: .25rem;
                            cursor: pointer;
                            transition: .3s;
                            background-color: rgba(255, 255, 255, 0.25);

                            &:hover {
                                background-color: rgba(255, 255, 255, 0.35);
                            }

                            &:active {
                                color: $tempColor;
                                background-color: $secondaryColor;
                            }
                        }

                        .canvas-body-sides-label-list.active {
                            color: $tempColor;
                            background-color: $secondaryColor;
                        }
                    }

                    .canvas-body-sides-image {
                        border-radius: .5rem;
                        width: 100%;
                        aspect-ratio: 4/2;
                        // min-height: 50vh;
                        @include flex-center-align;
                        overflow: hidden;
                        background-color: $secondaryColor;

                        .image-holder {
                            height: 100%;
                            width: auto;
                            overflow: hidden;
                            background-color: $secondaryColor;
                            position: relative;

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                            }

                            .printable-area {
                                position: absolute;
                                // background-color: rgba(255, 0, 0, 0.521);
                                border: 2px dashed $darkColor;
                                outline: 2px dashed $secondaryColor;
                                // box-shadow: 0 0 25px $secondaryColor;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;

                                div {
                                    height: 100% !important;
                                    width: 100% !important;
                                }
                            }
                        }
                    }

                    .canvas-body-sides-color {
                        @include flex-between-align;
                        justify-content: start;
                        flex-wrap: wrap;

                        .custom-img-holder-container {
                            // width: 85px;
                            width: 100%;
                            aspect-ratio: 5/7;
                            border-radius: .33rem;
                            line-height: 14px;
                            transition: .3s;
                            cursor: pointer;
                            position: relative;

                            &:hover {
                                background-color: $borderColor;
                            }

                            .custom-img-holder {
                                width: 100%;
                                aspect-ratio: 1/1;
                                overflow: hidden;
                                background-color: $secondaryColor;
                                border-radius: .33rem;
                                border: 1px solid $borderColor;

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }

                            &:hover .custom-delete {
                                display: flex;
                            }

                            .custom-delete {
                                position: absolute;
                                top: 0;
                                right: 0;
                                background-color: red;
                                border-radius: 50%;
                                height: 15px;
                                width: 15px;
                                @include flex-center-align;
                                transform: translate(25%, -25%);
                                color: white;
                                display: none;

                                i {
                                    @include flex-center-align;
                                }
                            }
                        }
                    }


                }

            }

            // Don't Interapt Here...
            .custom-canvas-body-sidebar.active-sidebar-content {
                height: 100%;
                width: 101px;

                .custom-canvas-body-sidebar-header {
                    border-right: 0px solid transparent;
                }

                .custom-canvas-body-sidebar-content {
                    transition: .5s;
                    width: 26px;

                    .custom-toggle-btn {
                        i {

                            &::before {
                                transform: rotate(0deg);
                            }
                        }
                    }

                    .custom-sidebar-all-content {
                        width: 1px;
                        border-right: 1px solid $borderColor;
                        transition: .5s;

                        .final-content-dis {
                            transition: .5s;
                            display: none;
                        }

                    }
                }

            }
        }
    }
}

.konvajs-content {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
}

.droppable-area {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    width: 300px;
    margin: 0 auto;
    min-height: 400px;
    /* Ensure the droppable area has some height */
}

.draggable-item {
    padding: 15px;
    margin: 5px 0;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: grab;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.draggable-item:active {
    cursor: grabbing;
}

.handle {
    font-size: 16px;
    margin-right: 10px;
    cursor: grab;
}

.canvas-body-sides-layer {
    // border: 2px solid $borderColor;

    .canvas-body-sides-layer-label {
        height: 50px;
        width: 50px;
        @include flex-center-align;
        background-color: $secondaryColor;
        // box-shadow: 0 0 10px $intensDarkColor;
        border-radius: 50%;
        cursor: pointer;
        transition: .3s;

        &:active {
            background-color: $intensDarkColor;
            color: $secondaryColor;
        }
    }

    .canvas-body-sides-layer-label.active {
        background-color: $intensDarkColor;
        color: $secondaryColor;
    }

    @include custom-scrollbar;

    .canvas-body-sides-layer-display {
        grid-template-rows: 28px 1fr 28px;
        // background-color: $sellerThemeColor;
        // border-radius: .5rem;
        transition: .3s;
        // box-shadow: 0 0 15px $intensDarkColor;
        overflow-y: scroll;
        overflow-x: hidden;

        .card {
            border: 0;
            background-color: transparent;

            .list-group-item {
                border-radius: .33rem;

                &:first-child {
                    border-radius: .33rem;
                }

                &:last-child {
                    border-radius: .33rem;
                }
            }
        }

        .top-suggest-line {
            div {
                height: 14px;
                width: 100%;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    top: 50%;
                    border-top: 2px dotted grey;
                    transform: translateY(-50%);
                    // background-color: $intensDarkColor;
                }
            }

            .line-first {
                border-left: 2px solid grey;
            }

            .line-second {
                border-right: 2px solid grey;
            }

            span {
                color: grey;
            }
        }

        .custom-list-items-container {
            // background-color: transparent;

            .custom-list-items {
                max-height: 60px;
                overflow: hidden;
                cursor: grab;
                border: 1px solid $borderColor;

                z-index: 99999;

                .custom-list-item-display {
                    text-align: center;

                    span {
                        .img-holder {
                            height: 50px !important;
                            width: 100px !important;
                            overflow: hidden !important;

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }

                &:active {
                    cursor: grabbing;
                }

                .delete-btn {
                    height: 25px;
                    width: 25px;
                    @include flex-center-align;
                    border-radius: .25rem;
                    background-color: rgb(255, 104, 104);
                    transition: .3s;
                    cursor: pointer;
                    z-index: 999999;

                    i {
                        color: $secondaryColor;
                    }

                    &:hover {
                        background-color: black;
                    }
                }
            }
        }
    }
}

.custom-list-items {
    max-height: 60px;
    overflow: hidden;
    cursor: grab;
    border: 1px solid $borderColor;

    z-index: 99999;

    .custom-list-item-display {
        text-align: center;

        span {
            .img-holder {
                height: 50px !important;
                width: 100px !important;
                overflow: hidden !important;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    &:active {
        cursor: grabbing;
    }

    .delete-btn {
        height: 25px;
        width: 25px;
        @include flex-center-align;
        border-radius: .25rem;
        background-color: rgb(255, 104, 104);
        transition: .3s;
        cursor: pointer;
        z-index: 999999;

        i {
            color: $secondaryColor;
        }

        &:hover {
            background-color: red;
        }
    }
}

@media(max-width: 768px) {
    .custom-canvas {
        .custom-canvas-row {
            .custom-canvas-header {
                height: 40px;
            }

            .custom-canvas-body {
                flex-grow: 1;
                height: calc(100% - 105px);
                overflow: hidden;
                flex-direction: column-reverse;

                .custom-canvas-body-sidebar {
                    // height: 92px;
                    height: 350px;
                    width: 100%;
                    flex-direction: column-reverse;

                    .custom-canvas-body-sidebar-header {
                        height: 70px;
                        width: 100%;
                        display: flex !important;

                        .custom-tab-list {
                            width: 60px;

                            span {
                                line-height: 12px !important;
                                font-size: 10px;
                            }
                        }
                    }

                    .custom-canvas-body-sidebar-content {
                        display: flex !important;
                        flex-direction: column-reverse;
                        width: 100%;
                        height: 280px;
                        // height: 22px;

                        .custom-toggle-btn {
                            width: 20%;
                            top: 0;
                            left: 50%;
                            transform: translate(-50%, 0px);
                            height: 22px;
                            border-radius: 0.35rem 0.35rem 0 0;
                            padding: 5px;

                            i {
                                font-size: 12px;

                                &::before {
                                    transform: rotate(90deg);
                                }
                            }
                        }

                        .custom-sidebar-all-content {
                            width: 100%;
                            height: 258px;
                            // height: 0px;
                            padding: 12px !important;

                            .custom-clipart-container {
                                .custom-clipart {

                                    .custom-img-holder-clipart {
                                        width: 28px;
                                        aspect-ratio: 1/1;
                                        overflow: hidden;

                                        span {
                                            cursor: pointer;
                                            transition: .3s;
                                        }
                                    }
                                }
                            }

                            .custom-upload-tab {
                                .img-list-data {
                                    min-height: 250px;
                                }
                            }
                        }
                    }
                }

                .custom-canvas-body-sidebar.active-sidebar-content {
                    // height: 230px;
                    height: 92px;
                    width: 100%;

                    .custom-canvas-body-sidebar-header {
                        border-right: 0px solid transparent;
                    }

                    .custom-canvas-body-sidebar-content {
                        transition: .5s;
                        width: 100%;
                        // height: 160px;
                        height: 22px;

                        .custom-toggle-btn {
                            i {
                                &::before {
                                    transform: rotate(-90deg);
                                }
                            }
                        }

                        .custom-sidebar-all-content {
                            width: 100%;
                            // height: 138px;
                            height: 0px;
                            border-right: 1px solid $borderColor;
                            transition: .5s;
                            padding: 0px !important;

                            .final-content-dis {
                                transition: .5s;
                                display: block;
                            }
                        }
                    }

                }

                .custom-canvas-body-content {
                    transition: .5s;
                    height: calc(100% - 350px);
                    // height: calc(100% - 92px);

                    .final-display-body-content {
                        .final-display-body-content-peta {
                            padding: 8px;
                        }

                        .canvas-body-sides-label {
                            font-size: 12px;
                        }

                        .canvas-body-sides-image {
                            aspect-ratio: 1/1;
                        }
                    }
                }

                .custom-canvas-body-content.active-sidebar-body {
                    // height: calc(100% - 230px);
                    height: calc(100% - 92px);
                }
            }

            .custom-canvas-footer {
                height: 65px;

                .custom-canvas-footer-product {
                    .img-holder {
                        width: 40px;
                    }
                }

                .custom-submit-div {
                    @include flex-center-align;
                    justify-content: end;
                    min-width: 130px;
                }
            }
        }
    }
}