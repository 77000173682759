@import 'var';
@import 'mixins';

.custom-seller-category {

    .custom-seller-category-slider {
        @include custom-slider-nav;

        .slick-track {
            display: flex;
        }

        .slick-slide>div {
            padding: 0 10px;
        }

        .slick-list {
            margin: 0 -10px;
        }

        .slider-category-container {
            width: 100%;
            text-decoration: none;
            gap: .5rem;

            .slider-category-img-container {
                width: 100%;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                }
            }

            h6 {
                color: $themeColor;
                text-transform: capitalize;
            }
        }
    }

    .custom-see-all-tab {
        height: 60px;
    }

    .seller-category-slider-new {
        @include custom-slider-nav;

        .seller-category-slider-new-item {
            background-color: $secondaryColor;
            border-radius: .5rem;
            overflow: hidden;

            .seller-category-slider-new-img {
                width: 30%;
                aspect-ratio: 1/1;
                overflow: hidden;
                display: flex;
                justify-content: center;

                img {
                    min-width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .seller-category-slider-new-content {
                width: 70%;
                display: grid;
                align-content: center;
            }
        }
    }

    .custom-seller-category-banner-final {
        .seller-category-slider-new-item {
            background-color: $intensDarkColor;
            color: $secondaryColor;
            border-radius: .5rem;
            overflow: hidden;

            .seller-category-slider-new-img {
                width: 35%;
                aspect-ratio: 3/2;
                overflow: hidden;
                display: flex;
                justify-content: center;

                img {
                    min-width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .seller-category-slider-new-content {
                width: 65%;
                display: grid;
                align-content: center;

                .custom-design-btn {
                    @include lightBtn;
                    text-align: center;
                    transition: .3s;
                    border-radius: .5rem;
                    letter-spacing: 2px;
                    color: $intensDarkColor;
                    border: 1px solid $secondaryColor;

                    &:hover {
                        background-color: $intensDarkColor;
                        box-shadow: inset 0 0 5px $secondaryColor;
                        color: $secondaryColor;
                    }
                }
            }
        }
    }

    // From SellerCategoryPage.jsx

    .custom-sub-category {
        display: flex;
        background-color: $sellerThemeColor;
        overflow: hidden;
        text-decoration: none;

        .custom-sub-category-img {
            width: 40%;
            aspect-ratio: 1/1;
            overflow: hidden;
            display: grid;
            align-content: center;

            img {
                height: auto;
                max-height: 100%;
                width: 100%;
                object-fit: cover;
                transform: scale(1);
                transition: .3s;
            }
        }

        .custom-sub-category-content {
            width: 60%;

            h3 {
                color: $darkColor;
                transition: .3s;
            }
        }

        &:hover .custom-sub-category-content h3 {
            color: $darkSubColor;
        }

        &:hover .custom-sub-category-img img {
            transform: scale(1.1);
        }
    }
}

@media (max-width: 768px) {
    .custom-seller-category {
        .custom-sub-category {
            .custom-sub-category-img {
                width: 35%;

                .custom-sub-category-content {
                    width: 65%;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .custom-seller-category {

        .custom-seller-category-banner-final {
            .seller-category-slider-new-item {
                .seller-category-slider-new-img {
                    width: 100%;
                }

                .seller-category-slider-new-content {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .seller-category-slider-new {
            .seller-category-slider-new-item {
                .seller-category-slider-new-img {
                    width: 100%;
                }

                .seller-category-slider-new-content {
                    width: 100%;
                }
            }
        }

    }
}