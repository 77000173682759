@import 'var';
@import 'mixins';

.custom-seller-category-search {
    width: 100%;

    div {
        border: 1px solid $borderColor;
        border-radius: 1.5rem;
        background-color: $secondaryColor;
        transition: .3s;

        input {
            background-color: transparent;
            border: 0;
            width: 100%;

            &:focus {
                outline: 0;
            }
        }
    }
}

.seller-product-page {

    .seller-product-bar {
        .accordion {
            --bs-accordion-border-color: transparent;

            .accordion-item {
                border-bottom: 1px solid $lightColor;
                border-radius: 0;

                &:first-of-type>.accordion-header .accordion-button {
                    border-radius: 0;
                }

                &:last-of-type>.accordion-header .accordion-button.collapsed {
                    border-radius: 0;
                }

                .accordion-button {
                    color: $themeColor;
                    font-weight: 600;
                    padding: .65rem 1.25rem;
                    transition: .6s;

                    &:not(.collapsed) {
                        color: $themeColor;
                    }

                    &:hover {
                        background-color: $lightColor;
                    }
                }

                .accordion-body {
                    padding-top: .25rem;

                    .seller-product-color {
                        color: $themeColor;
                        text-decoration: none;
                        text-transform: capitalize;

                        .seller-product-color-container {
                            height: 25px;
                            width: 25px;
                            border-radius: 50%;
                            background-color: white;
                            border: 1px solid $lightColor;
                        }
                    }

                    .custom-cursor {
                        cursor: pointer;
                        transition: .3s;

                        &:hover {
                            background-color: $lightColor;
                            border-radius: .5rem;
                        }
                    }
                }
            }
        }
    }

    .seller-product-detail {
        .seller-product-detail-container {
            border-radius: .5rem;
            overflow: hidden;
            transition: .3s;

            &:hover {
                background-color: $sellerThemeColor;
            }

            .seller-product-detail-img-container {
                width: 100%;
                aspect-ratio: 1/1;
                overflow: hidden;
                border-radius: .5rem;
                position: relative;

                div {
                    width: 100%;
                    aspect-ratio: 7/8;
                    overflow: hidden;
                    @include flex-center-align;
                    background-color: $subThemeColor;
                    cursor: pointer;

                    img {
                        height: 100%;
                        min-width: 100%;
                        object-fit: cover;
                    }

                    .img-one {
                        min-width: 100%;
                        height: 100%;
                        transition: .45s;
                    }

                    .img-two {
                        min-width: 0% !important;
                        height: 0% !important;
                        transition: .45s !important;
                        opacity: 0 !important;
                    }
                }

                &:hover div .img-one {
                    opacity: 0;
                    min-width: 0%;
                    height: 0%;
                }

                &:hover div .img-two {
                    min-width: 100% !important;
                    height: 100% !important;
                    opacity: 1 !important;
                }

                .seller-product-fixed-wish {
                    position: absolute;
                    background-color: white;
                    height: 40px;
                    width: 40px;
                    transform: translate(-25%, -25%);
                    border: 1px solid $lightColor;
                    bottom: 0;
                    right: 0;
                    border-radius: 50%;
                    z-index: 999;

                    i {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -40%);
                        transition: .3s;
                        color: $darkColor;
                        font-size: 18px;
                    }

                    &:hover i::before {
                        color: $subSecondaryColor;
                    }

                }
            }

            .seller-product-detail-cnt-container {
                a {
                    color: $darkColor;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .custom-slider-products-circle {
                    display: flex;
                    justify-content: start;

                    span {
                        height: 15px;
                        width: 15px;
                        border-radius: .25rem;
                        border: 1px solid rgb(109, 109, 109);
                    }
                }
            }


        }
    }
}

.custom-modal-accordion-container {
    @include custom-scrollbar;

    .modal-accordion-container {
        height: 73vh;
        overflow-y: scroll;
        overflow-x: hidden;

        .modal-accordion {
            .accordion {
                --bs-accordion-border-color: transparent;

                .accordion-item {
                    border-bottom: 1px solid $lightColor;
                    border-radius: 0;

                    &:first-of-type>.accordion-header .accordion-button {
                        border-radius: 0;
                    }

                    &:last-of-type>.accordion-header .accordion-button.collapsed {
                        border-radius: 0;
                    }

                    .accordion-button {
                        color: $themeColor;
                        font-weight: 600;
                        padding: .65rem 1.25rem;
                        transition: .6s;

                        &:not(.collapsed) {
                            color: $themeColor;
                        }

                        &:hover {
                            background-color: $lightColor;
                        }
                    }

                    .accordion-body {
                        padding-top: .25rem;

                        .seller-product-color {
                            color: $themeColor;
                            text-decoration: none;
                            text-transform: capitalize;

                            .seller-product-color-container {
                                height: 25px;
                                width: 25px;
                                border-radius: 50%;
                                background-color: white;
                                border: 1px solid $lightColor;
                            }
                        }

                        .custom-cursor {
                            cursor: pointer;
                            transition: .3s;

                            &:hover {
                                background-color: $lightColor;
                                border-radius: .5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 576px) {
    .seller-product-detail {
        .seller-product-detail-container {
            .seller-product-detail-cnt-container {
                a {
                    line-height: 1.35rem;
                }
            }
        }
    }
}