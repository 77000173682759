@import '_var';
@import '_mixins';

// .custom-signin-page {
//     min-height: 100vh;
//     position: relative;
//     overflow: hidden;

//     &::before {
//         z-index: -1;
//         content: '';
//         position: absolute;
//         width: 50%;
//         height: 100%;
//         background-color: skyblue;
//         left: 50%;
//         top: 0;
//         opacity: .15;
//     }

//     &::after {
//         z-index: -2;
//         content: '';
//         position: absolute;
//         width: 50%;
//         height: 100%;
//         background-color: skyblue;
//         top: 0;
//     }

//     .custom-signin-row {
//         border-radius: 1rem;
//         background-color: $secondaryColor;
//         position: relative;

//         .custom-close-btn {
//             position: absolute;
//             top: 15px;
//             right: 15px;
//             border-radius: 50%;
//             height: 35px;
//             @include flex-center-align;
//             text-decoration: none;
//             width: 35px;
//             transition: .3s;
//             color: $secondaryColor;
//             background-color: rgb(12, 118, 160);

//             &:hover {
//                 transform: rotate(90deg);
//                 font-size: 20px;
//             }
//         }

//         &::before {
//             z-index: -1;
//             content: '';
//             position: absolute;
//             width: 75px;
//             height: 75px;
//             background-image: url('../Images/dots.png');
//             background-size: cover;
//             right: 0%;
//             bottom: 0;
//             transform: translate(50%, 50%);

//         }

//         &::after {
//             z-index: -1;
//             content: '';
//             position: absolute;
//             width: 75px;
//             height: 75px;
//             background-image: url('../Images/dots.png');
//             background-size: cover;
//             left: 0%;
//             top: 0;
//             transform: translate(-50%, -50%);
//         }

//         .custom-submit-btn {
//             button {
//                 background-color: rgb(12, 118, 160);
//                 border: 3px solid rgb(12, 118, 160);
//                 padding: .25rem 1rem !important;

//                 &:hover {
//                     background-color: transparent;
//                     color: rgb(12, 118, 160);
//                 }
//             }
//         }

//         .custom-signin-img {
//             @include img-holder-5-3;
//             aspect-ratio: 2/1;

//             img {
//                 object-fit: contain;
//             }
//         }
//     }
// }

// @media(max-width:576px) {
//     .custom-signin-page {
//         .custom-signin-row {
//             .custom-close-btn {
//                 top: 10px;
//                 right: 10px;
//                 height: 30px;
//                 width: 30px;
//                 font-size: 14px;

//                 &:hover {
//                     transform: rotate(90deg);
//                     font-size: 16px;
//                 }
//             }
//         }
//     }
// }

.custom-signin-page {
    .custom-signin-page-row {
        min-height: 100vh;

        .custom-signin-page-img {
            display: grid;
            // align-content: space-between;
            min-height: 300px;

            position: relative;

            .custom-bg-dark-color {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
                z-index: -1;
            }

            .custom-img-dark-color {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: -2;
                background-image: url('../Images/login-page-image.jpg');
                background-size: cover;
                background-position: center center;
            }

            .logo-holder {
                background-color: rgba(255, 255, 255, 0.75);
                border-radius: .55rem;
                height: 50px;
                aspect-ratio: 3/1;
                overflow: hidden;
                transition: .1s;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }

                &:hover {
                    padding: 3px !important;
                }
            }

            .content-holder {
                color: $secondaryColor;

                h5 {
                    color: $secondaryColor;
                    letter-spacing: 0.5px;
                    line-height: 1.5rem;
                }
            }
        }

        .custom-signin-page-form {
            display: grid;
            align-content: center;
            background-color: $petaTempBgColor;
            .logo-holder {
                border-radius: .55rem;
                height: 60px;
                aspect-ratio: 3/1;
                overflow: hidden;
                transition: .1s;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }

                &:hover {
                    padding: 3px !important;
                }
            }

            .custom-signin-content {
                position: relative;

                .custom-sticky-icons {

                    .first-icon,
                    .second-icon,
                    .third-icon,
                    .fourth-icon {
                        position: absolute;
                        font-size: 3rem;
                    }

                    .first-icon {
                        top: 0;
                        left: 0;
                        transform: rotate(-15deg);
                    }

                    .second-icon {
                        top: 0;
                        right: 0;
                        font-size: 2rem;
                        transform: rotate(15deg);
                    }

                    .third-icon {
                        bottom: 0;
                        right: 0;
                        transform: rotate(15deg);
                    }

                    .fourth-icon {
                        bottom: 0;
                        left: 0;
                        font-size: 2rem;
                        transform: rotate(-15deg);
                    }
                }
            }

            .btn-list {
                button {
                    text-align: center;
                    border: 0px solid transparent;
                    border-radius: .25rem;
                    width: 50%;
                }

                .git-btn {
                    @include darkBtn;
                }

                .google-btn {
                    @include darkBtn;
                    background-color: #066EC2;
                }
            }

            .custom-submit-btn {
                button {
                    background-color: $tempColor;
                    border: 3px solid $tempColor;
                    padding: .55rem 1rem !important;

                    &:hover {
                        background-color: transparent;
                        color: $tempColor;
                    }
                }
            }
        }
    }
}