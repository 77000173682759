.custom-product-create {
    background-color: $sellerThemeColor;
    min-height: 100vh;

    .custom-header {
        transition: .3s;

        a {
            @include lightBtn;
            border: 2px solid $intensDarkColor;
            border-radius: .25rem;
            color: $intensDarkColor;
            transition: 1s;

            &:hover {
                background-color: $intensDarkColor;
                color: $secondaryColor;
            }

            &:hover i {
                width: auto;
                opacity: 1;
            }

            i {
                transition: .1s;
                width: 0px;
                opacity: 0;
                color: $secondaryColor;
            }
        }
    }
}


.pointer {
    cursor: pointer;
}

.preview-image {
    width: 100%;
    aspect-ratio: 1/1;

    div,
    a {
        height: 100%;
        width: 100%;

        img {
            // border: 0.5px solid #D2D2D2;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

// ------------
.canvas-bg-image {
    aspect-ratio: 1/1;

    img {
        object-fit: contain;
    }
}

.preview-img-overlay {
    z-index: 1;
}

.preview-img-background {
    z-index: 0;
}