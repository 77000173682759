@import 'var';
@import 'mixins';

.seller-store-listing {
    background-color: $sellerThemeColor;
    min-height: 100vh;

    .add-store-btn {
        @include dark-sub-btn;
        transition: .3s;
        border: 2px solid $darkSubColor;
        border-radius: .25rem;

        &:hover {
            background-color: transparent;
            color: $darkSubColor;
            border: 2px solid $darkSubColor;
        }
    }

    .container {
        border-bottom: 0px solid transparent;

        &:last-child {
            border-bottom: 1px solid $borderColor;
        }

        .custom-img-container {
            min-width: 75px;

            span {
                height: 15px;
                width: 15px;
                border-radius: 50%;
                background-color: $darkSubColor;
            }

            .custom-img-holder {
                max-width: 50px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid $borderColor;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .custom-img-holder-active {
                border: 2px solid $darkSubColor;
            }
        }

        .btn-css {
            div {
                cursor: pointer;
            }

            .dropdown {
                .btn {
                    padding: 0;
                    --bs-btn-bg: transparent;
                    border: 0px;

                    .show {
                        background-color: transparent !important;
                    }
                }

                .dropdown-toggle {
                    &::after {
                        display: none;
                    }
                }
            }

            .custom-link-btn {
                color: $themeColor;
                width: 35px;
                aspect-ratio: 1/1;
                border-radius: .25rem;
                @include flex-center-align;
                transition: .3s;

                &:hover {
                    background-color: $lightColor !important;
                }

            }
        }
    }
}

@media(max-width: 576px) {
    .custom-back-box {
        display: grid;
        gap: 1rem;
        @include seller-box;
        border-radius: 0rem;
        padding: .75rem;
    }

    .seller-store-listing {

        .container {
            border-bottom: 1px solid $borderColor !important;
            box-shadow: 0 0 10px $lightColor;

            .custom-img-container {
                .custom-img-holder {
                    width: 50px;
                }
            }
        }
    }
}