@import 'var';
@import 'mixins';

.seller-subscription-main-container {
    min-height: 100vh;

    .seller-subscription-sub-container-first {
        color: $secondaryColor;
        background-color: $intensDarkColor;

        .container {
            .row {
                .custom-seller-subscription-banner {
                    background-color: $secondaryColor;
                    color: $intensDarkColor;
                    box-shadow: inset 0 0 10px $intensDarkColor;
                    border: 2px solid $secondaryColor;

                    .get-started-btn {
                        background: linear-gradient(45deg, $intensDarkColor, $darkSubColor);
                        border: none;
                        border-radius: 50px;
                        color: white;
                        padding: .5rem 1rem;
                        cursor: pointer;
                        transition: all 0.3s ease;
                        position: relative;
                        overflow: hidden;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 300%;
                            height: 300%;
                            transition: all 0.5s ease;
                            border-radius: 40%;
                            transform: translate(-50%, -50%) scale(0.5);
                        }

                        &:hover {
                            background: linear-gradient(45deg, $darkSubColor, $intensDarkColor);
                            box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
                        }

                        &:hover:before {
                            transform: translate(-50%, -50%) scale(1);
                        }
                    }

                    .custom-seller-subscription-banner-btn {
                        border: 1px solid $borderColor;

                        button {
                            width: 100%;
                            border: 0px;
                            letter-spacing: 1px;
                            @include lightBtn;
                            transition: .3s;
                        }
                    }

                    .accordion {
                        --bs-accordion-bg: transparent;

                        .accordion-item {
                            background-color: transparent;
                            border-radius: 0px;
                            border: 0px;
                            border-top: 1px solid $borderColor;

                            .accordion-button {
                                background-color: transparent;
                            }

                            .accordion-button:not(.collapsed) {
                                background-color: transparent;
                            }

                            .apply-btn {
                                @include dark-sub-btn;
                                transition: .3s;

                                &:hover {
                                    background-color: $intensDarkColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .seller-subscription-sub-container-second {

        .seller-sub-img {
            width: 100%;
            aspect-ratio: 1/1;
            overflow: hidden;

            img {
                min-width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .custom-see-discount-btn {
            @include dark-sub-btn;
            transition: .3s;
            border: 2px solid $darkSubColor;
            border-radius: .5rem;

            &:hover {
                @include lightBtn;
                color: $darkSubColor;
                border: 2px solid $darkSubColor;
                border-radius: 0;
            }
        }
    }

    .seller-subscription-sub-container-third {
        background-color: $sellerThemeColor;

        .custom-info-img-container {
            width: 200px;
            aspect-ratio: 1/1;
            overflow: hidden;
            border-radius: 50%;
            border: 25px solid $lightColor;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .seller-subscription-sub-container-fourth {
        .custom-heading-text {
            color: #836FEC;
        }

        .custom-coupon-img {
            width: 150px;
            aspect-ratio: 1/1;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}


// 
// 
// 

.custom-price-list {
    .custom-img-container {
        overflow: hidden;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -99;
        }

        .custom-img-section {
            width: 100%;
            // min-height: 700px;
            // aspect-ratio: 1/1;
            position: relative;
            overflow: hidden;

            .custom-img-section-contain {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: .7;
                z-index: -50;
            }

            .custom-img-section-contain-color {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -51;
            }


            .custom-slider-content {
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                overflow: hidden;

                .custom-slider-content-list {
                    height: auto;
                    width: 100%;

                    .custom-slider-content-list-first {
                        display: grid;
                        align-content: start;
                        height: auto;
                        width: 100%;

                        .custom-slider-heading {
                            h1 {
                                color: $intensDarkColor;
                                font-weight: 600;
                                letter-spacing: -0.1rem;

                                span {
                                    transform: rotate(-2deg) !important;
                                    display: inline-flex !important;
                                    background-color: $petaTempColor;
                                    padding: 0 .5rem;
                                    border-radius: .75rem;
                                    color: $tempColor;
                                }
                            }

                            p {
                                color: $secondaryColor;
                            }
                        }

                        .custom-started-btn {
                            @include tempBtn;
                            border-radius: 3rem;
                            padding: .75rem 1.5rem;
                            @include flex-center-align;
                            // align-items: end;

                            &:hover {
                                background-color: #074235;
                            }
                        }
                    }
                }

                .custom-slider-content-list-second {
                    .price-list-item {
                        // color: $secondaryColor;
                        border-radius: 1rem;
                        overflow: hidden;
                        display: grid;
                        align-content: start;
                        background-color: $petaTempBgColor;
                        position: relative;

                        .first-content {
                            text-align: center;

                            b {
                                color: $tempColor;
                            }

                            a {
                                @include tempBtn;
                                border-radius: .5rem;
                                padding: .65rem;
                            }
                        }

                        .second-content {
                            border-radius: 1rem;
                            border: 1px solid $borderColor;

                            span {
                                height: 16px;
                                width: 16px;
                                @include flex-center-align;
                                background-color: $petaTempColor;
                                border-radius: 50%;
                                color: $tempColor;

                                i {
                                    @include flex-center-align;
                                }
                            }
                        }

                        .custom-lable {
                            position: absolute;
                            top: 1.5rem;
                            right: 1.5rem;
                            transform: rotate(5deg);
                            background-color: $tempColor;
                            padding: 0.25rem .5rem;
                            border-radius: .2rem;
                            color: $petaTempColor;
                        }
                    }

                    .price-list-item-free {
                        // color: $secondaryColor;
                        border-radius: 1rem;
                        overflow: hidden;
                        display: grid;
                        align-content: start;
                        background-color: transparent;
                        border: 1px solid $tempColor;
                        position: relative;

                        .first-content {
                            text-align: center;

                            b {
                                color: $tempColor;
                            }

                            a {
                                @include tempBtn;
                                border-radius: .5rem;
                                padding: .65rem;
                            }
                        }

                        .second-content {
                            border-radius: 1rem;
                            border: 1px solid $tempColor;

                            span {
                                height: 16px;
                                width: 16px;
                                @include flex-center-align;
                                background-color: $petaTempColor;
                                border-radius: 50%;
                                color: $tempColor;

                                i {
                                    @include flex-center-align;
                                }
                            }
                        }

                        .custom-lable {
                            position: absolute;
                            top: 1.5rem;
                            right: 1.5rem;
                            transform: rotate(5deg);
                            background-color: $tempColor;
                            padding: 0.25rem .5rem;
                            border-radius: .2rem;
                            color: $petaTempColor;
                        }
                    }

                    .free-price-list-item {
                        color: $themeColor;
                        background-color: transparent;
                        border: 2px solid $intensDarkColor;

                        .first-content {
                            text-align: center;

                            b {
                                color: $intensDarkColor;
                            }

                            a {
                                @include shadowLightBtn;
                                cursor: default;
                                border-radius: 3rem;
                                padding: 1rem 1rem;
                            }
                        }

                        .second-content {
                            border-radius: 1rem;
                            border: 1px solid $borderColor;

                            span {
                                height: 25px;
                                width: 25px;
                                @include flex-center-align;
                                background-color: $petaTempColor;
                                border-radius: 50%;
                                color: $tempColor;

                                i {
                                    @include flex-center-align;
                                }
                            }
                        }

                        // .custom-lable {
                        //     position: absolute;
                        //     top: 1.5rem;
                        //     right: 1.5rem;
                        //     transform: rotate(-2deg);
                        //     background-color: $petaTempColor;
                        //     padding: 0.25rem .5rem;
                        //     border-radius: .2rem;
                        //     color: $tempColor;
                        // }
                    }
                }
            }
        }
    }
}

.custom-active-subscription-plan {
    .card-container {
        width: 100%;
        // min-height: 300px;
        background-color: white;
        border-radius: 1rem;
        position: relative;

        &::before,
        &::after {
            content: " ";
            position: absolute;
            margin: -10px;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 1rem;
            background-image: linear-gradient(to bottom left, #04ac8473, $petaTempColor);
        }

        &::before {
            z-index: -1;
        }

        &::after {
            z-index: -2;
            filter: blur(15px);
        }
    }
}



@media (min-width: 1650px) {
    .custom-price-list {
        .custom-img-container {
            .custom-img-section {
                max-width: 1650px;
            }
        }
    }
}

@media (min-width: 1400px) and (max-width: 1650px) {
    .custom-price-list {
        .custom-img-container {
            .custom-img-section {
                max-width: 100%;
            }
        }
    }
}

@media (min-width: 992px) {
    .custom-price-list {
        .custom-img-container {
            border-radius: 33px;
        }
    }
}

@media(min-width: 1200px) and (max-width: 1400px) {
    .custom-price-list {
        .custom-img-container {
            .custom-img-section {
                max-width: 95%;
            }
        }
    }
}

@media (max-width: 1400px) {
    .custom-price-list {
        .custom-img-container {
            .custom-img-section {
                max-width: 100%;
            }
        }
    }
}

/* From Uiverse.io by kyle1dev */
.custom-radio-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    border-radius: 12px;
    // background: rgba(0, 0, 0, 0.2);
    // background: $petaTempBgColor;
    padding: 16px;
    // box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.custom-radio-container {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    transition:
        background-color 0.3s ease,
        transform 0.3s ease,
        box-shadow 0.3s ease;
    font-size: 16px;
    color: #333333;
    user-select: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.custom-radio-container:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transform: scale(1.03);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.custom-radio-container input[type="radio"] {
    opacity: 0;
    position: absolute;
}

.custom-radio-checkmark {
    position: relative;
    height: 24px;
    width: 24px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    transition:
        background-color 0.4s ease,
        transform 0.4s ease;
    margin-right: 12px;
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
}

.custom-radio-container input[type="radio"]:checked+.custom-radio-checkmark {
    background-color: #ffffff;
    border-color: $tempColor;
    box-shadow: 0 0 0 4px #11725b21;
    transform: scale(1.2);
    animation: pulse 0.6s forwards;
}

.custom-radio-checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.custom-radio-container input[type="radio"]:checked+.custom-radio-checkmark::after {
    display: block;
    left: 50%;
    top: 50%;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $tempColor;
    transform: translate(-50%, -50%);
}

@keyframes pulse {
    0% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1.2);
    }
}


.custom-modal-radio-select {
    .custom-radio-group {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        border-radius: 12px;
        background: transparent;
        padding: 16px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0);
    }

    .custom-radio-container {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 12px 20px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.2);
        transition:
            background-color 0.3s ease,
            transform 0.3s ease,
            box-shadow 0.3s ease;
        font-size: 16px;
        color: #333333;
        user-select: none;
        box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
    }

    .custom-radio-container:hover {
        background-color: rgba(255, 255, 255, 0.3);
        transform: scale(1.03);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    }

    .custom-radio-container input[type="radio"] {
        opacity: 0;
        position: absolute;
    }

    .custom-radio-checkmark {
        position: relative;
        height: 24px;
        width: 24px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.3);
        transition:
            background-color 0.4s ease,
            transform 0.4s ease;
        margin-right: 12px;
        display: inline-block;
        vertical-align: middle;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
    }

    .custom-radio-container input[type="radio"]:checked+.custom-radio-checkmark {
        background-color: #ffffff;
        border-color: #007bff;
        box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.2);
        transform: scale(1.2);
        animation: pulse 0.6s forwards;
    }

    .custom-radio-checkmark::after {
        content: "";
        position: absolute;
        display: none;
    }

    .custom-radio-container input[type="radio"]:checked+.custom-radio-checkmark::after {
        display: block;
        left: 50%;
        top: 50%;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #007bff;
        transform: translate(-50%, -50%);
    }

    .custom-radio-container input[type="radio"]:checked+.custom-radio-checkmark+.d-grid {
        background-color: #007bff;
        /* Blue background */
        color: white;
        /* Change text color to white for visibility */
        border-radius: 8px;
        padding: 12px;
        transition: background-color 0.3s ease;
    }

    @keyframes pulse {
        0% {
            transform: scale(1.2);
        }

        50% {
            transform: scale(1.4);
        }

        100% {
            transform: scale(1.2);
        }
    }
}