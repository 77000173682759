@import '_var';
@import '_mixins';

.custom-create-store-tab {
  .custom-card {
    @include seller-box;
    background-color: $secondaryColor;
    // background-image: linear-gradient(to bottom, $sellerThemeColor, $sellerThemeColor, rgba(0, 0, 192, 0.25));
    box-shadow: 0px 0px 35px $lightColor;
  }

  .nav {
    border: 1px solid $petaThemeColor;
    border-radius: .5rem;
    overflow: hidden;
    background-color: #e3e4e5 !important;
  }

  .nav-link {
    color: $themeColor;
    background-color: transparent;
    position: relative;
    margin: 0;
    overflow: hidden;
    border-radius: 0rem;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    background-color: $intensDarkColor;
    border-radius: 0rem;

    .custom-triangle {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      // border-top: 20px solid $secondaryColor;
      // border-bottom: 20px solid $secondaryColor;
      // border-left: 20px solid transparent;
      border-top: 20px solid #e3e4e5;
      border-bottom: 20px solid #e3e4e5;
      border-left: 20px solid transparent;
    }
  }

  position: relative;

  .custom-loader {
    z-index: 9999;
    position: absolute;
    height: 100%;
    width: 100%;
    @include flex-center-align;
    background-color: rgba(255, 255, 255, 0.6);
    top: 0;
    right: 0;
  }
}

.custom-contain-align-center {
  background-color: $sellerThemeColor;
  border-radius: 1rem;
}

.custom-identity-container {
  text-align: center;
  max-width: 1134px;
  margin: 0 auto;
}

.option {
  background: $secondaryColor;
  border-radius: 10px;
  aspect-ratio: 1/1;
  border: 3px solid $borderColor;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s, border 0.3s;
  transition: 0.3s;
  display: grid;
  align-content: center;
  color: $paragraphColor;

  &:hover {
    box-shadow: 0 0 10px $borderColor;
  }

  p {
    font-size: 14px;
  }
}

.option.selected {
  border: 3px solid $paragraphColor;
  box-shadow: 0 0 10px $paragraphColor;
  color: $darkColor;
}

.back-button {
  margin: 10px;
  border-radius: .5rem;
  letter-spacing: 3px;
  @include shadowLightBtn;
  border: 3px solid $paragraphColor;
  transition: 0.3s;
  gap: .25rem;
  color: $paragraphColor;

  &:hover {
    color: $darkColor;
    border: 3px solid $darkColor;
  }
}

.next-button {
  margin: 10px;

  .button {
    position: relative;
    width: 125px;
    letter-spacing: 3px;
    height: 100%;
    padding: .5rem 0rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 3px solid $borderColor;
    border-radius: .5rem;
    overflow: hidden;
    background-color: transparent;

    .button__text {
      transform: translateX(13px);
      color: $paragraphColor;
      font-weight: 600;
    }

    .button__icon {
      position: absolute;
      right: 0;
      height: 100%;
      width: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }

    .svg {
      width: 30px;
    }

    &:hover {
      background: #34974d;
    }

    &:hover .button__icon {
      width: 100%;
      transform: translateX(0);
    }
  }

  .final-active-btn {
    cursor: no-drop;

    .button__icon {
      width: 100%;
      transform: translateX(0);
    }
  }

  .button,
  .button__icon,
  .button__text {
    transition: all 0.3s;
  }

  .button.active-btn {
    background-color: $paragraphColor;
    border: 1px solid $paragraphColor;
  }

  .button.active-btn .button__icon {
    background-color: $darkSellerColor;
    color: $secondaryColor;
  }

  .button.active-btn .button__text {
    color: $secondaryColor;
  }
}

.custom-btn-disabled {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
  color: gray;
  opacity: 0.5;
}

.custom-check-border {
  border: 1px solid $paragraphColor;
  border-radius: .25rem;

  input,
  label {
    cursor: pointer;
  }
}

/* Checkbox styles */
.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  align-items: center;

  span {
    position: absolute;
    left: 1rem;
    font-size: 20px;
    text-align: left;
    color: transparent;
    justify-content: start;
    display: flex;
  }

  input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid $intensDarkColor;
    border-radius: 3px;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 99;
    }

    &:checked {
      background-color: $paragraphColor;
      border-color: $paragraphColor;
    }
  }

  input:checked+span {
    display: flex;
    color: $secondaryColor;
  }

}

.custom-radio {
  input {
    height: 20px;
    width: 20px;
  }
}

.custom-store-detail {

  input,
  textarea,
  select {
    border-radius: .25rem;
    border: 1px solid $borderColor;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .custom-store-image {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;

    svg {
      font-size: 100px;
    }
  }

  .custom-store-detail-content {
    div {
      .custom-span-height {
        // height: 16px;
        overflow: hidden;
      }

      input,
      textarea,
      select {
        padding: .25rem;
      }

      label {
        font-size: 14px;
      }
    }
  }
}


.custom-fixed-back {
  position: fixed;
  bottom: 5%;
  right: 5%;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 30px;
  @include flex-center-align;
  background-color: $intensDarkColor;
  color: $secondaryColor;
  border: 3px solid $secondaryColor;
}

@media (max-width: 576px) {
  .option {
    aspect-ratio: 5/2;
  }

  .custom-create-store-tab {
    .nav {
      border-bottom: 1px solid $petaThemeColor;
    }

    .nav-link {
      color: $themeColor;
      font-size: 12px;
      padding: .25rem;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
      font-size: 12px;
      padding: .25rem;

      .custom-triangle {
        border-top: 13px solid $secondaryColor;
        border-bottom: 13px solid $secondaryColor;
        border-left: 13px solid transparent;
      }
    }
  }

  .custom-store-detail {

    .custom-store-detail-content {
      div {
        label {
          font-size: 12px;
        }

        input,
        textarea,
        select {
          font-size: 12px;
          padding: .25rem;
        }
      }
    }
  }

  .custom-fixed-back {
    height: 50px;
    width: 50px;
    font-size: 20px;
  }
}

.custom-over-hidden {

  input,
  select {
    width: 100%;
  }
}

.custom-platform-cart {
  border: 2px solid $borderColor;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;

  .img-holder {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    // background-color: red;
    padding: 1.5rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      transition: all .3s;
    }

    transition: all .3s;
  }

  transition: all .3s;

  .content-holder {
    width: 100%;
  }

  // .sticky-div {
  //   z-index: 2;

  //   &::after {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     height: 100%;
  //     width: 100%;
  //     z-index: 1;
  //     background-color: transparent;
  //     // filter: blur(30px);
  //   }
  // }

  &:hover .img-holder img {
    transform: scale(1.05);
  }
}

.custom-platform-cart.deactive {
  cursor: default;

  .img-holder,
  .content-holder {
    filter: blur(4px);
  }

  &:hover .img-holder img {
    transform: scale(1);
  }
}

.custom-platform-cart.shopify {
  border: 2px solid $tempColor;
  background-color: rgba($color: $tempColor, $alpha: 0.2);
  color: $tempColor;

  p {
    color: $tempColor !important;
  }
}

.custom-platform-cart.zoho {
  border: 2px solid #f9b238;
  background-color: rgba($color: #f9b238, $alpha: 0.2);
  color: #f9b238;

  p {
    color: #f9b238 !important;
  }
}

.custom-platform-cart.snapstore {
  border: 2px solid $infoColor;
  background-color: rgba($color: $infoColor, $alpha: 0.2);
  color: $infoColor;

  p {
    color: $infoColor !important;
  }
}