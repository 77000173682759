@import 'var';
@import 'mixins';

.custom-see-all-tab {
    height: 60px;
}

.custom-password-input {
    position: relative;

    .custom-eye {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

.custom-seller-category-slider-trending {

    .slick-track {
        display: flex;
        margin-left: 0;
        margin-right: 0;
    }

    .slick-slide>div {
        padding: 0 15px;
    }

    .slick-list {
        margin: 0 -15px;
    }


    .custom-slider-products {
        background-color: transparent;
        overflow: hidden;
        color: $themeColor;
        // transition: .3s;

        &:hover {
            background-color: $sellerThemeColor;
        }

        &:hover .slider-trending-container .img-one {
            opacity: 0;
            min-width: 0%;
            height: 0%;
        }

        &:hover .slider-trending-container .img-two {
            min-width: 100% !important;
            height: 100% !important;
            opacity: 1 !important;
        }


        .slider-trending-container {
            width: 100%;
            aspect-ratio: 7/8;
            overflow: hidden;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $subThemeColor;

            img {
                height: 100%;
                min-width: 100%;
                object-fit: cover;
            }

            .img-one {
                min-width: 100%;
                height: 100%;
                transition: .45s;
            }

            .img-two {
                min-width: 0% !important;
                height: 0% !important;
                transition: .45s !important;
                opacity: 0 !important;
            }
        }

        .custom-slider-products-content {
            .custom-slider-products-circle {
                display: flex;
                justify-content: start;

                span {
                    height: 15px;
                    width: 15px;
                    border-radius: .25rem;
                    border: 1px solid rgb(109, 109, 109);
                }
            }
        }
    }

    @include custom-slider-nav;
}

.custom-seller-category-banner-final {
    .seller-category-slider-new-item {
        background-color: $intensDarkColor;
        color: $secondaryColor;
        border-radius: .5rem;
        overflow: hidden;

        .seller-category-slider-new-img {
            width: 35%;
            aspect-ratio: 3/2;
            overflow: hidden;
            display: flex;
            justify-content: center;

            img {
                min-width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .seller-category-slider-new-content {
            width: 65%;
            display: grid;
            align-content: center;

            .custom-design-btn {
                @include lightBtn;
                text-align: center;
                border-radius: .5rem;
                letter-spacing: 2px;
                color: $intensDarkColor;
                border: 1px solid $secondaryColor;
                box-shadow: 0 0 5px $intensDarkColor;
                transition: .3s;

                &:hover {
                    background-color: transparent;
                    box-shadow: inset 0 0 5px $intensDarkColor;
                    color: $secondaryColor;
                }
            }
        }
    }
}

.custom-remove-number {

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.custom-home-bulk {
    background-color: $sellerThemeColor;

    .custom-home-bulk-container {
        .custom-home-bulk-container-row {
            background-color: $secondaryColor;
            border-radius: 1rem;
            overflow: hidden;
            width: 85%;

            .slick-track {
                display: flex;
            }

            .slick-slide>div {
                padding: 0 0px;
            }

            .slick-list {
                margin: 0 -0px;
            }

            .custom-sld-items {

                cursor: pointer;
                opacity: .6;
                transition: .3s;
                transform: scale(.80);

                a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .product-border {
                    border: 3px solid transparent;
                    border-radius: .5rem;
                    width: 100%;
                    aspect-ratio: 1/1;
                    transition: .6s;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .slick-active {
                .custom-sld-items {
                    .product-border {
                        border: 3px solid $themeColor;
                    }

                    opacity: 1;
                    transform: scale(1);
                }
            }

            .custom-home-bulk-container-col {

                .custom-input-box {
                    width: 150px;
                    border: 1px solid $borderColor;
                    border-radius: .25rem;

                    input[type=number]::-webkit-inner-spin-button,
                    input[type=number]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input {
                        width: 100%;
                        border: 0px;

                        &:focus {
                            outline: 0px;
                        }
                    }

                    span {
                        background-color: $sellerThemeColor;
                        width: 100%;
                        border-left: 1px solid $borderColor;
                    }
                }

                .custom-bg-color {
                    background-color: $sellerThemeColor;
                    border-radius: .5rem;
                }

                .custom-success-bg {
                    background-color: rgb(208, 255, 208);
                    border-radius: .5rem;

                    .custom-color-success {
                        color: rgb(0, 66, 0);
                    }
                }

                .custom-link-btn {
                    @include dark-sub-btn;
                    border-radius: .5rem;
                    border: 3px solid $darkSubColor;
                    transition: .3s;

                    &:hover {
                        background-color: transparent;
                        color: $darkSubColor;
                    }
                }
            }
        }
    }
}

.custom-seller-category-search {
    width: 100%;

    div {
        border: 1px solid $intensDarkColor;
        border-radius: 1.5rem;
        background-color: $secondaryColor;
        transition: .3s;
        position: relative;
        padding-right: 0 !important;

        input {
            background-color: transparent;
            border: 0;
            width: 100%;

            &:focus {
                outline: 0;
            }
        }

        .custom-close-btn {
            cursor: pointer;
            // background-color: red;
            border-radius: 50%;
            padding: 0.25rem .25rem;
            @include flex-center-align;
            transition: .3s;

            &:hover {
                background-color: $borderColor;
            }
        }

        .custom-search-btn {
            right: 0;
            top: 0;
            height: 100%;
            padding: 0.5rem 1rem;
            border-radius: 0 2rem 2rem 0;
            background-color: $intensDarkColor;
            color: $secondaryColor;
            border: 1px solid $intensDarkColor;
            letter-spacing: 1px;
            text-decoration: none;
        }
    }

}

@media(max-width: 992px) {
    .custom-home-bulk {
        .custom-home-bulk-container {
            .custom-home-bulk-container-row {
                width: 100%;
            }
        }
    }
}

@media (max-width: 576px) {

    .custom-seller-category-banner-final {
        .seller-category-slider-new-item {
            .seller-category-slider-new-img {
                width: 100%;
            }

            .seller-category-slider-new-content {
                width: 100%;
                text-align: center;
            }
        }
    }
}

.common-pagination-style {
    ul {
        margin: 0px;

        .page-item.disabled {
            .page-link {
                background-color: $tempColor;
                color: $secondaryColor;
            }
        }

        .page-item {
            .page-link {
                color: $tempColor;

                &:active,
                &:focus {
                    outline: 0;
                    box-shadow: 0 0 0 transparent;
                }
            }

            &:first-child .page-link {
                background-color: $petaTempColor;
                color: $tempColor;
            }

            &:last-child .page-link {
                background-color: $petaTempColor;
                color: $tempColor;
            }
        }
    }
}

.notification-bell {
    position: fixed;
    // bottom: 20px;
    // bottom: 90px;
    bottom: 40px;
    // right: 20px;
    right: 15px;
    z-index: 500;

    .loader {
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        border-radius: 50%;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        transition: 0.2s;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #2b9a80;
            border-radius: 50%;
            z-index: -2;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #2b9a80;
            border-radius: 50%;
            z-index: -3;
            animation: loop 1s 0s infinite;
        }

        &:hover {
            background-color: $tempColor;
        }

        &:hover svg {
            color: white;
        }

        svg {
            color: $petaTempColor;
            transform: scale(1.2);
            transition: 0.2s;
        }

        .point {
            position: absolute;
            top: -4px;
            left: -4px;
            width: 27px;
            height: 27px;
            background-color: $petaTempBgColor;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $tempColor;
            color: $tempColor;
            font-size: 13px;
        }
    }

    .loader-stop {
        &::before {
            display: none;
        }
    }

    @keyframes loop {
        0% {
            background-color: $tempColor;
            width: 100%;
            height: 100%;
        }

        100% {
            background-color: #04ac8400;
            width: 150%;
            height: 150%;
        }
    }

}

@media(max-width: 768px) {
    .notification-bell {
        .loader {
            padding: 10px;

            .point {
                width: 22px;
                height: 22px;
                font-size: 10px;
            }

            svg {
                transform: scale(1);
            }
        }
    }
}

@media(max-width: 768px) {
    .notification-bell {
        // bottom: 10px;
        bottom: 90px;
        right: 25px;

        .loader {
            padding: 7px;

            .point {
                width: 18px;
                height: 18px;
                font-size: 8px;
            }

            svg {
                transform: scale(.8);
            }
        }
    }
}

.custom-list-active {
    .point {
        position: relative;
        // bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        width: 8px;
        height: 8px;
        background-color: $tempColor;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 1px;
            background-color: $tempColor;
            border-radius: 25px;
            animation: newLoop 1s 0s infinite;
        }
    }

    @keyframes newLoop {
        0% {
            background-color: $tempColor;
            width: 1px;
            height: 1px;
        }

        100% {
            background-color: #04ac8400;
            width: 30px;
            height: 30px;
        }
    }
}