.custom-store-setting {
    background-color: $sellerThemeColor;
    min-height: 100vh;

    .seller-store-setting-container {
        @include seller-box;
        @include custom-scrollbar;

        ::-webkit-scrollbar {
            height: 5px;
            background-color: rgb(221, 221, 221);
        }

        ::-webkit-scrollbar-thumb {
            background: rgb(59, 59, 59);
        }

        .custom-tabs {
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            display: flex;
            justify-content: start;
            flex-wrap: nowrap;
            padding-top: 5px;

            .nav-item,
            &>.nav-link {
                flex: none;
            }

            .nav-item {
                display: inline-block;
            }
        }

        .nav-tabs {
            border-bottom: 1px solid $lightColor;

            .nav-link {
                color: $darkSubColor;
                font-weight: 600;

                &.active {
                    background-color: $darkSubColor;
                    color: $secondaryColor;
                }
            }
        }


    }

    .custom-store-btn {
        @include dark-sub-btn;
        transition: .3s;
        border: 3px solid $darkSubColor;
        background-color: transparent;
        color: $darkSubColor;
        padding: .25rem 1rem;

        &:hover {
            box-shadow: 6px 6px 5px $darkSubColor;
        }
    }

    .custom-img-holder {
        width: 100%;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 1rem;
        cursor: pointer;
        border: 4px solid transparent;
        position: relative;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
            transition: 2s;
        }

        &:hover img {
            object-position: bottom;
        }

        .custom-span {
            position: absolute;
            top: 10px;
            left: 10px;
            height: 30px;
            width: 30px;
            @include flex-center-align;
            border-radius: .25rem;
            border: 1px solid $borderColor;
            background-color: rgba(255, 255, 255, 0.5);
        }

        .custom-span-active {
            background-color: $darkColor;
            color: $secondaryColor;
        }
    }

    .custom-img-holder-active {
        border: 4px solid $darkColor;

        img {
            opacity: 1;
        }
    }

    .cs-table {
        border-collapse: collapse;
        overflow: hidden;
        border-radius: .5rem .5rem 0 0;

        th,
        td {
            border: 1px solid $borderColor;
            padding: 5px;
            text-align: left;
            background-color: transparent;
        }

        th {
            background-color: $lightColor;
        }

        // tr:nth-child(even) {
        //     background-color: $lightColor;
        // }
    }

    .slider {
        background-color: #ffffff2b;
        border-radius: 100px;
        padding: 1px;
        cursor: pointer;
        transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
        align-items: center;
        position: relative;
        display: block;
        width: 40px;
        height: 20px;
        box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset,
            #22cc3f 0px 0px 0px 0px inset, rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
    }

    .slider::after {
        content: "";
        display: flex;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;
        width: 15px;
        height: 15px;
        background-color: #e3e3e3;
        border-radius: 200px;
        position: absolute;
        box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
        transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        will-change: left, background-color;
    }

    .switch input[type="checkbox"]:checked+.slider {
        box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, #22cc3f 0px 0px 0px 2px inset, #22cc3f 0px 0px 0px 24px inset,
            rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
    }

    .switch input[type="checkbox"]:checked+.slider::after {
        left: 22px;
    }

    .switch input[type="checkbox"] {
        display: none;
    }

    .custom-btns {
        justify-content: space-around;

        i {
            height: 25px;
            width: 25px;
            border: 1px solid $borderColor;
            border-radius: .25rem;
            @include flex-center-align;
            background-color: $lightColor;
            transition: .3s;
            cursor: pointer;
        }

        .custom-btns-edit {
            &:hover {
                background-color: #22cc3f;
                color: white;
            }
        }

        .custom-btns-delete {
            &:hover {
                background-color: red;
                color: white;
            }
        }
    }

    .custom-border-box {
        @include seller-box;
    }

}



@media(max-width: 400px) {
    .custom-store-setting {
        .seller-store-setting-container {

            .custom-tabs {
                .nav-link {
                    font-size: 14px;
                    padding: .4rem .75rem;
                }
            }
        }
    }
}